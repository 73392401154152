<template>
	<div class="container">
		<top-header></top-header>
		<div class="supplier width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/datalake' }">全球产品大数据湖</el-breadcrumb-item>
				<el-breadcrumb-item>全球竞争对手调研</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="title">
				<p>全球竞争对手调研</p>
				<p>Global Market Competitor Survey</p>
			</div>
			<div class="hive">
				<div class="hive-item">
					<div class="list" v-for="(item,index) in list" :key="index" @click="gsClick(item.id)">
						<div class="item">
							<img :src="item.background_img">
						</div>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				list:[]
			}
		},
		created() {
			this.getList();
		},
		methods: {
			gsClick(id) {
				this.$router.push({
					query: {
						id: id
					},
					name: 'supplierinfo'
				})
			},
			getList(){
				this.$get('front/companies/get_list',{}).then(res =>{
					for(let i = 0;i<res.data.data.data.length;i++){
						res.data.data.data[i].background_img = this.$store.state.imaUrl + res.data.data.data[i].background_img;
					}
					this.list = res.data.data.data;
				})
			},
			gotoUrl(url) {
				if (this.$route.fullPath != url && url) {
					this.$router.push(url);
					window.scroll(0, 0);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	body {
		background-color: #f5f5f5;
	}

	.title {
		font-size: 30px;
		letter-spacing: 6px;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 100px;
		p {
			padding-left: 10px;
			box-sizing: border-box;

			&:last-child {
				padding-left: 0;
				font-size: 16px;
				letter-spacing: 0px;
			}
		}
		@media screen and(min-width:320px) and (max-width:767px) {
			font-size: 24px;
			letter-spacing: 5px;
			margin-top: 50px;
			p{
				&:last-child{
					font-size: 14px;
				}
			}
		}
	}

	.hive {
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;

		.hive-item {
			position: relative;
			width: 535px;
			height: 300px;
			@media screen and(min-width:320px) and (max-width:767px) {
				width: 330px;
			}
			.list {
				position: absolute;
				cursor: pointer;

				.item {
					width: 200px;
					height: 120px;
					position: relative;
					@media screen and(min-width:320px) and (max-width:767px) {
						width: 120px;
						height: 80px;
					}
					img {
						width: 100%;
						height: 100%;
					}

					// 	&::before {
					// 		content: ""; //不需要展现文字等内容,所以设置为空字符   
					// 		width: 0px;
					// 		border-bottom: 60px solid transparent;
					// 		border-top: 60px solid transparent;
					// 		border-right: 40px solid red;
					// 		position: absolute;
					// 		left: -40px;
					// 		top: 0px;
					// 	}

					// 	&::after {
					// 		content: ""; //不需要展现文字等内容,所以设置为空字符   
					// 		width: 0px;
					// 		border-bottom: 60px solid transparent;
					// 		border-top: 60px solid transparent;
					// 		border-left-width: 40px;
					// 		border-left-style: solid;
					// 		border-left-color: red;
					// 		position: absolute;
					// 		right: -40px;
					// 		top: 0px;
					// 	}
				}

				&:nth-child(1) {
					// border: 1px solid;
					top: 0px;
					left: 0;
				}

				&:nth-child(2) {
					top: -60px;
					left: 170px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: -40px;
						left: 105px;
					}
				}

				&:nth-child(3) {
					top: 0px;
					left: 340px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 0;
						left: 210px;
					}
				}

				&:nth-child(4) {
					top: 60px;
					left: 170px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 42px;
						left: 105px;
					}
				}

				&:nth-child(5) {
					top: 120px;
					left: 0;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 83px;
						left: 0;
					}
				}

				&:nth-child(6) {
					top: 180px;
					left: 169px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 125px;
						left: 105px;
					}
				}

				&:nth-child(7) {
					top: 120px;
					left: 338px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 83px;
						left: 210px;
					}
				}

				&:nth-child(8) {
					top: 240px;
					left: 0;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 165px;
						left: 0;
					}
				}

				&:nth-child(9) {
					top: 240px;
					left: 338px;
					@media screen and(min-width:320px) and (max-width:767px) {
						top: 165px;
						left: 210px;
					}
				}
			}
		}
	}

	.supplier {
		margin-top: 65px;
		min-height: 89vh;
		padding-top: 20px;
	}
</style>
